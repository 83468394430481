@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "certiblokicons";
	src: url("assets/fonts/certiblok-icons/font/certiblokicons.eot?37560840");
	src: url("assets/fonts/certiblok-icons/font/certiblokicons.eot?37560840#iefix") format("embedded-opentype"),
		url("assets/fonts/certiblok-icons/font/certiblokicons.woff?37560840") format("woff"),
		url("assets/fonts/certiblok-icons/font/certiblokicons.ttf?37560840") format("truetype"),
		url("assets/fonts/certiblok-icons/font/certiblokicons.svg?37560840#certiblokicons") format("svg");
	font-weight: normal;
	font-style: normal;
}

i {
	font-family: "certiblokicons";
	font-style: normal;
	font-weight: normal;
	speak: never;
	font-size: 24px;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;

	text-align: center;
	/* opacity: .8; */

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;

	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;

	/* Animation center compensation - margins should be symmetric */
	/* remove if not needed */

	/* You can be more comfortable with increased icons size */
	/* font-size: 120%; */

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

:not(.MuiTableContainer-root, .horizontal-scroll) {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

:not(.MuiTableContainer-root, .horizontal-scroll)::-webkit-scrollbar {
	display: none;
}
